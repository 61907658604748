import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "*",
    name: 'PageNotFound',
    meta: { requiresAuth: false },
    component: () => import('../views/PageNotFound.vue')
  },
  {
    path: '/',
    name: 'PageHomeMain',
    meta: { requiresAuth: true },
    component: () => import('../views/PageHome.vue')
  },
  {
    path: '/home',
    name: 'PageHome',
    meta: { requiresAuth: true },
    component: () => import('../views/PageHome.vue')
  },
  {
    path: '/login',
    name: 'PageLogin',
    meta: { requiresAuth: false },
    component: () => import('../views/PageLogin.vue')
  },
  {
    path: '/racionais/config',
    name: 'RacionaisConfig',
    meta: { requiresAuth: true },
    component: () => import('../views/PageRacionaisConfig.vue')
  },
  {
    path: '/racionais/monitoramento',
    name: 'RacionaisConfigMonitoramento',
    meta: { requiresAuth: false },
    component: () => import('../views/PageRacionaisConfigMonitoramento')
  },
  {
    path: '/redefinir-senha',
    name: 'PageRedefinirSenha',
    meta: { requiresAuth: false },
    component: () => import('../views/PageRedefinirSenha.vue')
  },
  {
    path: '/assinante/tickers',
    name: 'PageTickers',
    meta: { requiresAuth: false },
    component: () => import('../views/PageTickers.vue')
  },
  {
    path: '/assinante/minha-conta',
    name: 'PageMinhaContaPagamento',
    meta: { requiresAuth: false },
    component: () => import('../views/PageMinhaContaPagamento.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !router.app.$store.state.user) {
    next({
      path: "/login",
      params: { nextUrl: to.fullPath },
    });
  } else {
    next();
  }
});

export default router
