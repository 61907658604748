import Vue from 'vue';
import Vuex from 'vuex';
import utils from '../plugins/utils.js';
import PackInfo from './../../package.json';
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);
Vue.config.devtools = true;

function validaRacionalPlano (racionais_user, racionais) {
    let value_return = [];
    
    racionais.forEach(function (racional) {
        if (racionais_user.includes(racional.racional)) {
            value_return.push(racional);
        }
    });

    return value_return;
}

export default new Vuex.Store({
    state: {
        appOpen: true,
        wsMsgReceived: '',
        version: PackInfo.buildVersion || PackInfo.version,
        user: null,
        racionais: [
            {
                racional: 'CONGELAMENTO',
                name: 'Congelamento',
                config: [
                    {
                        key: 'percentual',
                        type: 'float',
                        name: 'Percentual',
                        default: 7.5,
                        rules: [
                            value => !!value || 'Informe um valor',
                            value => value >= 0 || 'Valor mínimo é 0',
                            value => value <= 100 || 'Valor máximo é 100'
                        ]
                    },
                    {
                        key: 'tipo_aviso_sonoro',
                        type: 'select',
                        name: 'Tipo de aviso sonoro',
                        opcoes: [
                            {
                                value: 'BEEP1',
                                name: 'Beep Curto'
                            },
                            {
                                value: 'BEEP2',
                                name: 'Beep Longo'
                            },
                            {
                                value: 'BEEP3',
                                name: 'Beep Grave'
                            },
                            {
                                value: 'FRASE',
                                name: 'Frase Completa'
                            },
                            {
                                value: 'PALAVRA',
                                name: 'Palavra'
                            }
                        ]
                    },
                    {
                        key: 'tickers_ignorar',
                        type: 'autocomplete',
                        name: 'Lista de EXCLUSÃO de tickers para essa leitura',
                    }
                ]
            },
            {
                racional: 'LEILAO',
                name: 'Leilão',
                config: [
                    {
                        key: 'tipo_aviso_sonoro_leilao',
                        type: 'select',
                        name: 'Tipo de aviso sonoro Leilão',
                        opcoes: [
                            {
                                value: 'NORMAL',
                                name: 'Aviso de Leilão'
                            },
                            {
                                value: 'PERDER_HORARIO',
                                name: 'Somente quando Leilão perder horário'
                            },
                            {
                                value: 'PERDER_HORARIO_LEILAO',
                                name: 'Aviso de Leilão e quando perder horário'
                            }
                        ]
                    },
                    {
                        key: 'tipo_aviso_sonoro',
                        type: 'select',
                        name: 'Tipo de aviso sonoro',
                        opcoes: [
                            {
                                value: 'BEEP1',
                                name: 'Beep Curto'
                            },
                            {
                                value: 'BEEP2',
                                name: 'Beep Longo'
                            },
                            {
                                value: 'BEEP3',
                                name: 'Beep Grave'
                            },
                            {
                                value: 'FRASE',
                                name: 'Frase Completa'
                            },
                            {
                                value: 'PALAVRA',
                                name: 'Palavra'
                            }
                        ]
                    },
                    {
                        key: 'tickers_ignorar',
                        type: 'autocomplete',
                        name: 'Lista de EXCLUSÃO de tickers para essa leitura'
                    }
                ]
            },
            {
                racional: 'DISTORCAO',
                name: 'Distorção',
                config: [
                    {
                        key: 'intervalo_minutos',
                        type: 'int',
                        name: 'Intervalo minutos',
                        default: 5,
                        rules: [
                            value => !!value || 'Informe um valor',
                            value => value >= 0 || 'Valor mínimo é 0',
                            value => value <= 15 || 'Valor máximo é 15'
                        ]
                    },
                    {
                        key: 'entre_1',
                        type: 'float.group1',
                        name: 'Entre (R$)',
                        default: 0.00,
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'entre_a_1',
                        type: 'float.group1',
                        name: 'à (R$)',
                        default: 10.00,
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'percentual_1',
                        type: 'float.group1',
                        name: 'Percentual',
                        default: 7.5,
                        rules: [
                            value => !!value || 'Informe um valor',
                            value => value >= 0 || 'Valor mínimo é 0',
                            value => value <= 100 || 'Valor máximo é 100'
                        ]
                    },
                    {
                        key: 'entre_2',
                        type: 'float.group2',
                        name: 'Entre (R$)',
                        default: 10.00,
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'entre_a_2',
                        type: 'float.group2',
                        name: 'à (R$)',
                        default: 20.00,
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'percentual_2',
                        type: 'float.group2',
                        name: 'Percentual',
                        default: 7.5,
                        rules: [
                            value => !!value || 'Informe um valor',
                            value => value >= 0 || 'Valor mínimo é 0',
                            value => value <= 100 || 'Valor máximo é 100'
                        ]
                    },
                    {
                        key: 'entre_3',
                        type: 'float.group3',
                        name: 'Acima de (R$)',
                        default: 20.00,
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'percentual_3',
                        type: 'float.group3',
                        name: 'Percentual',
                        default: 7.5,
                        rules: [
                            value => !!value || 'Informe um valor',
                            value => value >= 0 || 'Valor mínimo é 0',
                            value => value <= 100 || 'Valor máximo é 100'
                        ]
                    },
                    {
                        key: 'tipo_aviso_sonoro',
                        type: 'select',
                        name: 'Tipo de aviso sonoro',
                        opcoes: [
                            {
                                value: 'BEEP1',
                                name: 'Beep Curto'
                            },
                            {
                                value: 'BEEP2',
                                name: 'Beep Longo'
                            },
                            {
                                value: 'BEEP3',
                                name: 'Beep Grave'
                            },
                            {
                                value: 'FRASE',
                                name: 'Frase Completa'
                            },
                            {
                                value: 'PALAVRA',
                                name: 'Palavra'
                            }
                        ]
                    },
                    {
                        key: 'tickers_ignorar',
                        type: 'autocomplete',
                        name: 'Lista de EXCLUSÃO de tickers para essa leitura'
                    }
                ]
            },
            {
                racional: 'LOTAO',
                name: 'Lotão',
                config: [
                    {
                        key: 'quantidade',
                        type: 'int',
                        name: 'Quantidade',
                        default: 50000,
                        rules: [
                            value => !!value || 'Informe um valor',
                            value => value >= 0 || 'Valor mínimo é 0',
                            value => value <= 11000000 || 'Valor máximo é 1,1 milhões'
                        ]
                    },
                    {
                        key: 'tipo',
                        type: 'select',
                        name: 'Tipo',
                        default: 'multiplicacao',
                        opcoes: [
                            {
                                value: 'multiplicacao',
                                name: 'Multiplicação da média'
                            },
                            {
                                value: 'simples',
                                name: 'Simples'
                            }
                        ]
                    },
                    {
                        key: 'quantidade_multiplicacao',
                        type: 'int',
                        name: 'Quantidade multiplicação (apenas para tipo multiplicação)',
                        default: 5,
                        rules: [
                            value => !!value || 'Informe um valor',
                            value => value >= 1 || 'Valor mínimo é 1',
                            value => value <= 20 || 'Valor máximo é 20'
                        ]
                    },
                    {
                        key: 'percentagem_expressiva',
                        type: 'int',
                        name: 'Percentagem Expressiva',
                        default: 5,
                        rules: [
                            value => !!value || 'Informe um valor',
                            value => value >= 1 || 'Valor mínimo é 1',
                            value => value <= 10 || 'Valor máximo é 10'
                        ]
                    },
                    {
                        key: 'tipo_aviso_sonoro',
                        type: 'select',
                        name: 'Tipo de aviso sonoro',
                        opcoes: [
                            {
                                value: 'BEEP1',
                                name: 'Beep Curto'
                            },
                            {
                                value: 'BEEP2',
                                name: 'Beep Longo'
                            },
                            {
                                value: 'BEEP3',
                                name: 'Beep Grave'
                            },
                            {
                                value: 'FRASE',
                                name: 'Frase Completa'
                            },
                            {
                                value: 'PALAVRA',
                                name: 'Palavra'
                            }
                        ]
                    },
                    {
                        key: 'tickers_ignorar',
                        type: 'autocomplete',
                        name: 'Lista de EXCLUSÃO de tickers para essa leitura'
                    }
                ]
            },
            {
                racional: 'LOTE_ESCONDIDO',
                name: 'Lote Escondido',
                config: [
                    {
                        key: 'tam_aparente_1',
                        type: 'int.group1',
                        name: 'tamanho aparente entre',
                        default: 1000,
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'tam_aparente_2',
                        type: 'int.group1',
                        name: 'à',
                        default: 2000,
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'tam_value_1',
                        type: 'int.group1',
                        name: 'Número mínimo de recorrência',
                        default: 35,
                        rules: [
                            value => !!value || 'Informe um valor',
                            value => value >= 0 || 'Valor mínimo é 0',
                            value => value <= 60 || 'Valor máximo é 60'
                        ]
                    },                    {
                        key: 'tam_aparente_3',
                        type: 'int.group2',
                        name: 'tamanho aparente entre',
                        default: 2000,
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'tam_aparente_4',
                        type: 'int.group2',
                        name: 'à',
                        default: 4000,
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'tam_value_2',
                        type: 'int.group2',
                        name: 'Número mínimo de recorrência',
                        default: 20,
                        rules: [
                            value => !!value || 'Informe um valor',
                            value => value >= 0 || 'Valor mínimo é 0',
                            value => value <= 60 || 'Valor máximo é 60'
                        ]
                    },                    {
                        key: 'tam_aparente_5',
                        type: 'int.group3',
                        name: 'tamanho aparente maior que',
                        default: 4000,
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'tam_value_3',
                        type: 'int.group3',
                        name: 'Número mínimo de recorrência',
                        default: 10,
                        rules: [
                            value => !!value || 'Informe um valor',
                            value => value >= 0 || 'Valor mínimo é 0',
                            value => value <= 60 || 'Valor máximo é 60'
                        ]
                    },
                    {
                        key: 'tipo_aviso_sonoro',
                        type: 'select',
                        name: 'Tipo de aviso sonoro',
                        opcoes: [
                            {
                                value: 'BEEP1',
                                name: 'Beep Curto'
                            },
                            {
                                value: 'BEEP2',
                                name: 'Beep Longo'
                            },
                            {
                                value: 'BEEP3',
                                name: 'Beep Grave'
                            },
                            {
                                value: 'FRASE',
                                name: 'Frase Completa'
                            },
                            {
                                value: 'PALAVRA',
                                name: 'Palavra'
                            }
                        ]
                    },
                    {
                        key: 'tickers_ignorar',
                        type: 'autocomplete',
                        name: 'Lista de EXCLUSÃO de tickers para essa leitura'
                    }
                ]
            },
            {
                racional: 'SNIPER',
                name: 'Sniper (beta)',
                config: [
                    {
                        key: 'tipo_aviso_sonoro',
                        type: 'select',
                        name: 'Tipo de aviso sonoro',
                        opcoes: [
                            {
                                value: 'BEEP1',
                                name: 'Beep Curto'
                            },
                            {
                                value: 'BEEP2',
                                name: 'Beep Longo'
                            },
                            {
                                value: 'BEEP3',
                                name: 'Beep Grave'
                            },
                            {
                                value: 'FRASE',
                                name: 'Frase Completa'
                            },
                            {
                                value: 'PALAVRA',
                                name: 'Palavra'
                            }
                        ]
                    },
                    {
                        key: 'partir_de_que_quantidade',
                        type: 'int',
                        name: 'A partir de qual quantidade acumulada',
                        default: 10000,
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    }
                ]
            }
        ],
        racionaismonitoramento: [
            {
                racional: 'MONITORAMENTO_PRESENCA_PLAYER_BOOK',
                name: 'Presença Player Book',
                config: [
                    {
                        key: 'corretoras_monitorar',
                        type: 'autocomplete_corretora',
                        name: 'Nome do agente'
                    },
                    {
                        key: 'lado_book',
                        type: 'select',
                        name: 'Lado Book',
                        opcoes: [
                            {
                                value: 'COMPRA',
                                name: 'Compra'
                            },
                            {
                                value: 'VENDA',
                                name: 'Venda'
                            },
                            {
                                value: 'COMPRA_VENDA',
                                name: 'Compra e Venda'
                            }
                        ]
                    },
                    {
                        key: 'partir_de_que_quantidade',
                        type: 'int',
                        name: 'Quantidade maior ou igual à',
                        default: 1000,
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'percentual_profundidade',
                        type: 'float',
                        name: 'Percentual de profundidade',
                        default: 1,
                        rules: [
                            value => !!value || 'Informe um valor',
                            value => value >= 0 || 'Valor mínimo é 0',
                            value => value <= 100 || 'Valor máximo é 100'
                        ]
                    },
                    {
                        key: 'horario_monitoramento',
                        type: 'time',
                        name: 'Horário de monitoramento',
                        default: "",
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'tipo_aviso_sonoro',
                        type: 'select',
                        name: 'Tipo de aviso sonoro',
                        opcoes: [
                            {
                                value: 'BEEP1',
                                name: 'Beep Curto'
                            },
                            {
                                value: 'BEEP2',
                                name: 'Beep Longo'
                            },
                            {
                                value: 'BEEP3',
                                name: 'Beep Grave'
                            },
                            {
                                value: 'FRASE',
                                name: 'Frase Completa'
                            },
                            {
                                value: 'PALAVRA',
                                name: 'Palavra'
                            }
                        ]
                    },
                    {
                        key: 'tickers_monitorar',
                        type: 'autocomplete_tickers',
                        name: 'Lista de tickers para MONITORAR nessa leitura'
                    }
                ],
            },
            {
                racional: 'MONITORAMENTO_PRESENCA_PLAYER_TT',
                name: 'Presença Player TT',
                config: [
                    {
                        key: 'corretoras_monitorar',
                        type: 'autocomplete_corretora',
                        name: 'Nome do agente'
                    },
                    {
                        key: 'lado_agressao',
                        type: 'select',
                        name: 'Lado da Agressão',
                        opcoes: [
                            {
                                value: 'COMPRA',
                                name: 'Compra'
                            },
                            {
                                value: 'VENDA',
                                name: 'Venda'
                            },
                            {
                                value: 'COMPRA_VENDA',
                                name: 'Compra e Venda'
                            }
                        ]
                    },
                    {
                        key: 'partir_de_que_quantidade',
                        type: 'int',
                        name: 'Quantidade maior ou igual à',
                        default: 1000,
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'horario_monitoramento',
                        type: 'time',
                        name: 'Horário de monitoramento',
                        default: "",
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'tipo_aviso_sonoro',
                        type: 'select',
                        name: 'Tipo de aviso sonoro',
                        opcoes: [
                            {
                                value: 'BEEP1',
                                name: 'Beep Curto'
                            },
                            {
                                value: 'BEEP2',
                                name: 'Beep Longo'
                            },
                            {
                                value: 'BEEP3',
                                name: 'Beep Grave'
                            },
                            {
                                value: 'FRASE',
                                name: 'Frase Completa'
                            },
                            {
                                value: 'PALAVRA',
                                name: 'Palavra'
                            }
                        ]
                    },
                    {
                        key: 'tickers_monitorar',
                        type: 'autocomplete_tickers',
                        name: 'Lista de tickers para MONITORAR nessa leitura'
                    }
                ],
            },
            {
                racional: 'MONITORAMENTO_PRESENCA_PLAYER_SALDO',
                name: 'Presença Player Saldo',
                config: [
                    {
                        key: 'corretoras_monitorar',
                        type: 'autocomplete_corretora',
                        name: 'Nome do agente'
                    },
                    {
                        key: 'lado_posicao',
                        type: 'select',
                        name: 'Lado da posição',
                        opcoes: [
                            {
                                value: 'COMPRADO',
                                name: 'Comprado'
                            },
                            {
                                value: 'VENDIDO',
                                name: 'Vendido'
                            },
                            {
                                value: 'COMPRADO_VENDIDO',
                                name: 'Comprado ou Vendido'
                            }
                        ]
                    },
                    {
                        key: 'partir_de_que_quantidade',
                        type: 'int',
                        name: 'Quantidade maior ou igual à',
                        default: 10000,
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'horario_monitoramento',
                        type: 'time',
                        name: 'Horário de monitoramento',
                        default: "",
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'tipo_aviso_sonoro',
                        type: 'select',
                        name: 'Tipo de aviso sonoro',
                        opcoes: [
                            {
                                value: 'BEEP1',
                                name: 'Beep Curto'
                            },
                            {
                                value: 'BEEP2',
                                name: 'Beep Longo'
                            },
                            {
                                value: 'BEEP3',
                                name: 'Beep Grave'
                            },
                            {
                                value: 'FRASE',
                                name: 'Frase Completa'
                            },
                            {
                                value: 'PALAVRA',
                                name: 'Palavra'
                            }
                        ]
                    },
                    {
                        key: 'tickers_monitorar',
                        type: 'autocomplete_tickers',
                        name: 'Lista de tickers para MONITORAR nessa leitura'
                    }
                ]
            },
            {
                racional: 'MONITORAMENTO_ANALISE_SALDO',
                name: 'Análise de Saldo',
                config: [
                    {
                        key: 'corretoras_monitorar',
                        type: 'autocomplete_corretora',
                        name: 'Nome do agente'
                    },
                    {
                        key: 'lado_posicao',
                        type: 'select',
                        name: 'Lado da posição',
                        opcoes: [
                            {
                                value: 'COMPRADO',
                                name: 'Comprado'
                            },
                            {
                                value: 'VENDIDO',
                                name: 'Vendido'
                            },
                            {
                                value: 'COMPRADO_VENDIDO',
                                name: 'Comprado ou Vendido'
                            }
                        ]
                    },
                    {
                        key: 'x_vezes_maior',
                        type: 'int',
                        name: 'Quantas vezes maior',
                        default: 5,
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'horario_monitoramento',
                        type: 'time',
                        name: 'Horário de monitoramento',
                        default: "",
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'tipo_aviso_sonoro',
                        type: 'select',
                        name: 'Tipo de aviso sonoro',
                        opcoes: [
                            {
                                value: 'BEEP1',
                                name: 'Beep Curto'
                            },
                            {
                                value: 'BEEP2',
                                name: 'Beep Longo'
                            },
                            {
                                value: 'BEEP3',
                                name: 'Beep Grave'
                            },
                            {
                                value: 'FRASE',
                                name: 'Frase Completa'
                            },
                            {
                                value: 'PALAVRA',
                                name: 'Palavra'
                            }
                        ]
                    },
                    {
                        key: 'tickers_monitorar',
                        type: 'autocomplete_tickers',
                        name: 'Lista de tickers para MONITORAR nessa leitura'
                    }
                ]
            }
        ],
        racionaisalgoritmo : [
            {
                racional: 'ALGORITMO_POV',
                name: 'POV',
                config: [
                    {
                        key: 'corretoras_monitorar',
                        type: 'autocomplete_corretora',
                        name: 'Nome do agente'
                    },
                    {
                        key: 'quantidade_acumulada_minima',
                        type: 'int',
                        name: 'Quantidade acumulada mínima',
                        default: 0,
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'horario_monitoramento',
                        type: 'time',
                        name: 'Horário de monitoramento',
                        default: "",
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'tipo_aviso_sonoro',
                        type: 'select',
                        name: 'Tipo de aviso sonoro',
                        opcoes: [
                            {
                                value: 'BEEP1',
                                name: 'Beep Curto'
                            },
                            {
                                value: 'BEEP2',
                                name: 'Beep Longo'
                            },
                            {
                                value: 'BEEP3',
                                name: 'Beep Grave'
                            },
                            {
                                value: 'FRASE',
                                name: 'Frase Completa'
                            },
                            {
                                value: 'PALAVRA',
                                name: 'Palavra'
                            }
                        ]
                    },
                    {
                        key: 'tickers_monitorar',
                        type: 'autocomplete_tickers',
                        name: 'Lista de tickers para MONITORAR nessa leitura'
                    }
                ]
            },
            {
                racional: 'ALGORITMO_TWAP',
                name: 'TWAP',
                config: [
                    {
                        key: 'corretoras_monitorar',
                        type: 'autocomplete_corretora',
                        name: 'Nome do agente'
                    },
                    {
                        key: 'frequencia',
                        type: 'int',
                        name: 'Frequencia',
                        default: 0,
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'horario_monitoramento',
                        type: 'time',
                        name: 'Horário de monitoramento',
                        default: "",
                        rules: [
                            value => !!value || 'Informe um valor',
                        ]
                    },
                    {
                        key: 'tipo_aviso_sonoro',
                        type: 'select',
                        name: 'Tipo de aviso sonoro',
                        opcoes: [
                            {
                                value: 'BEEP1',
                                name: 'Beep Curto'
                            },
                            {
                                value: 'BEEP2',
                                name: 'Beep Longo'
                            },
                            {
                                value: 'BEEP3',
                                name: 'Beep Grave'
                            },
                            {
                                value: 'FRASE',
                                name: 'Frase Completa'
                            },
                            {
                                value: 'PALAVRA',
                                name: 'Palavra'
                            }
                        ]
                    },
                    {
                        key: 'tickers_monitorar',
                        type: 'autocomplete_tickers',
                        name: 'Lista de tickers para MONITORAR nessa leitura'
                    }
                ]
            }
        ],
        status_connection: "connecting"
    },
    getters: {
        appOpen: state => {
            return state.appOpen
        },
        getUser: state => {
            return state.user
        },
        getRacionais: state => {
            return validaRacionalPlano(state.user.racionais, state.racionais)
        },
        getRacionaisAlgoritmo: state => {
            return validaRacionalPlano(state.user.racionais, state.racionaisalgoritmo)
        },
        getRacionaisMonitoramento: state => {
            return validaRacionalPlano(state.user.racionais, state.racionaismonitoramento)
        },
    },
    mutations: {
        setAppOpen: (state, value) => {
            value = utils.clonar(value)
            state.appOpen = !!value
        },
        setUser: (state, value) => {
            value = utils.clonar(value)
            state.user = value
        }
    },
    actions: {
        setAppOpen: ({ commit }, value) => {
            commit('setAppOpen', value)
        },
        setUser: ({ commit }, value) => {
            commit('setUser', value)
        }
    },
    plugins: [createPersistedState({
        paths: ['user', 'version'],
        storage: window.localStorage,
    })]
})
